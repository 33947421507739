import * as React from "react";
//import Masonry from "react-masonry-component";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import GLightbox from "glightbox";

class Gallery extends React.Component {
  componentDidMount() {
    const lightbox = GLightbox({
      selector: ".img-box",
      loop: true,
    });
  }

  render() {
    const childElements = this.props.elements.map((element, i) => {
      return (
        <a
          key={i}
          href={"images/hd/" + element.src}
          className="img-box"
          data-type="image"
        >
          <img
            key={i}
            style={{ width: "100%", display: "block" }}
            src={"images/" + element.src}
            alt={"image-" + i}
          />
        </a>
      );
    });

    return (
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
        <Masonry gutter="10px">{childElements}</Masonry>
      </ResponsiveMasonry>
    );
  }
}

export default Gallery;
