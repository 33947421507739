export default function Credits(props) {
  return props.locale === "EN" ? en(props) : cn(props);
}

function cn(props) {
  return (
    <div className="content-about-container">
      <div className="container-about">
        <svg
          onClick={props.closeAbout}
          viewport="0 0 20 20"
          width="20"
          height="20"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="0" y="0" width="100%" height="100%" fillOpacity="0" />
          <line x1="1" y1="21" x2="21" y2="1" stroke="black" strokeWidth="3" />
          <line x1="1" y1="1" x2="21" y2="21" stroke="black" strokeWidth="3" />
        </svg>
      </div>
      <div className="content-about">
        <h1 className=""> W/AV/E</h1>
        <h2 className="">海洋為藝術家</h2>
        <p className="subtitle-about">
          <b>Thomas Roussel的實驗性藝術作品</b>
        </p>
        <br />
        <div>
          <p>
            <br />
            “藝文薈澳：澳門國際藝術雙年展2021” 特展 – 新濠博亞娛樂{" "}
            <a
              target="_blank"
              href="https://www.cityofdreamsmacau.com/en/whats-on/art-macao"
              rel="noreferrer"
            >
              《海洋LA MER》{" "}
            </a>{" "}
            藝術展
            <br />
            作曲家及藝術總監
            <a
              target="_blank"
              href="http://www.thomasroussel.fr/"
              rel="noreferrer"
            >
              Thomas Roussel{" "}
            </a>
            <br />
            聲音人工智能及機器學習
            <a target="_blank" href="https://robinmeier.net/" rel="noreferrer">
              Robin Meier
            </a>
            <br />
            視覺人工智能及機器學習
            <a
              target="_blank"
              href="https://www.screen-club.com/"
              rel="noreferrer"
            >
              Martial Geoffre Rouland
            </a>
            <br />
            視覺數碼藝術家
            <a
              target="_blank"
              href="https://www.arnaudlaffond.com/"
              rel="noreferrer"
            >
              Arnaud Laffond
            </a>{" "}
            <br />
            <br />
          </p>
          <p>
            執行製片人: Ivan Striga
            <br />
            項目協調人:{" "}
            <a target="_blank" href="http://bureauplato.com/" rel="noreferrer">
              Anne Becker/ PLATÔ
            </a>{" "}
            <br />
            編劇:{" "}
            <a
              target="_blank"
              href="https://www.rhetorique-conseil.com/"
              rel="noreferrer"
            >
              Camille Balenieri
            </a>{" "}
            <br />
            網站開發:{" "}
            <a
              target="_blank"
              href="https://www.screen-club.com/"
              rel="noreferrer"
            >
              Martial Geoffre Rouland
            </a>
            <br />
            視頻導演:{" "}
            <a
              target="_blank"
              href="https://www.adriencombes.com/ "
              rel="noreferrer"
            >
              Adrien Combes
            </a>{" "}
            <br />
            攝影師:{" "}
            <a target="_blank" href="https://dschft.com/" rel="noreferrer">
              Denys Schelfhaut
            </a>
          </p>
          <br />
          <br />
          <hr />
          <br />
          <br />
          <p>
            <strong>音樂家</strong>
          </p>
          <br />
          <div className="bloc-3">
            <p>
              <strong>小提琴 1</strong>
              <br /> <br />
              Mathilde Marsal
              <br />
              Arnaud Pienizny
              <br />
              Lison Favard
              <br />
              Jean Phillipe Kuzma
              <br />
              Arnaud Vallin
              <br />
              Irene Duval
              <br />
              Alice Bourlier
              <br />
              Marc Desjardins
              <br />
              Rozarta Luka
              <br />
              Clementine Bousquet
              <br />
              Paul Rouger
              <br />
              Roxanna Rastegar
              <br />
              Clemence Meriaux
              <br />
              Camille Verhoeven
              <br />
              <br />
            </p>
            <p>
              <strong>小提琴 2</strong>
              <br /> <br />
              Philippe Morel
              <br />
              Glen Roussel
              <br />
              Emelyne Chirol
              <br />
              Hugo Boulanger
              <br />
              Joseph Metral
              <br />
              Claire Bucelle
              <br />
              Lea Vandenhelsken
              <br />
              Anna Sypniewski
              <br />
              Clara Froger&nbsp;
              <br />
              Clara Jaszczyszyn
              <br />
              Pauline Hauswirth
              <br />
              Eleonore Grimbert
              <br />
              Stephanie Padel
              <br />
              <br />
            </p>
            <p>
              <strong>大提琴   </strong>&nbsp;
              <br /> <br />
              Maia Collette
              <br />
              Caro Sypniewsky
              <br />
              Jeremie Billet
              <br />
              Ella Jarridge
              <br />
              Maude Pages
              <br />
              Alexis Derouin
              <br />
              Solene Chevalier
              <br />
              Clara Germont
              <br />
              Justine Metral
              <br />
              Rafael Cumont-Vioque
              <br />
              <br />
            </p>
            <p>
              <strong>男高音 </strong>
              <br /> <br />
              Alexandra Kondo
              <br />
              Camille Borsarello
              <br />
              Elsa Seger
              <br />
              Antonin Lefaure
              <br />
              Caroline Pauvert
              <br />
              Maeva Laignelot
              <br />
              Ivan Cerveau
              <br />
              Oriane Pocard
              <br />
              Julien Lo Pinto
              <br />
              Lise Orivel
            </p>
          </div>
          <br />
          <hr />
          <br />
          <br />
          <div>
            <p>
              指揮: Thomas Roussel <br />
              樂團承包商: Lison Favard
              <br />
              音響工程師兼樂譜混音師: Stéphane Reichart
              <br />
              樂章於法國布洛涅-比揚古的塞納河音樂廳錄製 <br />
            </p>
          </div>
          <div className="bloc-3">
            <p>
              聯絡 : <a href="mailto:contact@w-av-e.com">contact@w-av-e.com</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
function en(props) {
  return (
    <div className="content-about-container">
      <div className="container-about">
        <svg
          onClick={props.closeAbout}
          viewport="0 0 20 20"
          width="20"
          height="20"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="0" y="0" width="100%" height="100%" fillOpacity="0" />
          <line x1="1" y1="21" x2="21" y2="1" stroke="black" strokeWidth="3" />
          <line x1="1" y1="1" x2="21" y2="21" stroke="black" strokeWidth="3" />
        </svg>
      </div>
      <div className="content-about">
        <h1 className=""> W/AV/E</h1>
        <h2 className="">The Ocean as the Artist</h2>
        <p className="subtitle-about">
          <b>An experimental artwork directed by Thomas Roussel</b>
        </p>
        <br />
        <div>
          <p>
            <a
              target="_blank"
              href="https://www.cityofdreamsmacau.com/en/whats-on/art-macao"
              rel="noreferrer"
            >
              Art Macao – LA MER
            </a>
            <br />
            Art Exhibition by Melco Part of the Art Macau Bienale
            <br />
            Music composer & artistic director:{" "}
            <a
              target="_blank"
              href="http://www.thomasroussel.fr/"
              rel="noreferrer"
            >
              Thomas Roussel&rsquo;s{" "}
            </a>
            <br />
            Audio Artificial intelligence & machine learning:{" "}
            <a target="_blank" href="https://robinmeier.net/" rel="noreferrer">
              Robin Meier
            </a>
            <br />
            Visual Artificial intelligence & machine learning:{" "}
            <a
              target="_blank"
              href="https://www.screen-club.com/"
              rel="noreferrer"
            >
              Martial Geoffre Rouland
            </a>
            <br />
            Visual digital artist:{" "}
            <a
              target="_blank"
              href="https://www.arnaudlaffond.com/"
              rel="noreferrer"
            >
              Arnaud Laffond
            </a>{" "}
            <br />
            <br />
          </p>
          <p>
            Executive producer: Ivan Striga
            <br />
            Project coordinator:{" "}
            <a target="_blank" href="http://bureauplato.com/" rel="noreferrer">
              Anne Becker/ PLATÔ
            </a>{" "}
            <br />
            Writer:{" "}
            <a
              target="_blank"
              href="https://www.rhetorique-conseil.com/"
              rel="noreferrer"
            >
              Camille Balenieri
            </a>{" "}
            <br />
            Website development:{" "}
            <a
              target="_blank"
              href="https://www.screen-club.com/"
              rel="noreferrer"
            >
              Martial Geoffre Rouland
            </a>
            <br />
            Video director:{" "}
            <a
              target="_blank"
              href="https://www.adriencombes.com/ "
              rel="noreferrer"
            >
              Adrien Combes
            </a>{" "}
            <br />
            Photographer:{" "}
            <a target="_blank" href="https://dschft.com/" rel="noreferrer">
              Denys Schelfhaut
            </a>
          </p>
          <br />
          <br />
          <hr />
          <br />
          <br />
          <p>
            <strong>Musicians</strong>
          </p>
          <br />
          <div className="bloc-3">
            <p>
              <strong>Violins 1</strong>
              <br /> <br />
              Mathilde Marsal
              <br />
              Arnaud Pienizny
              <br />
              Lison Favard
              <br />
              Jean Phillipe Kuzma
              <br />
              Arnaud Vallin
              <br />
              Irene Duval
              <br />
              Alice Bourlier
              <br />
              Marc Desjardins
              <br />
              Rozarta Luka
              <br />
              Clementine Bousquet
              <br />
              Paul Rouger
              <br />
              Roxanna Rastegar
              <br />
              Clemence Meriaux
              <br />
              Camille Verhoeven
              <br />
              <br />
            </p>
            <p>
              <strong>Violins 2 </strong>
              <br /> <br />
              Philippe Morel
              <br />
              Glen Roussel
              <br />
              Emelyne Chirol
              <br />
              Hugo Boulanger
              <br />
              Joseph Metral
              <br />
              Claire Bucelle
              <br />
              Lea Vandenhelsken
              <br />
              Anna Sypniewski
              <br />
              Clara Froger&nbsp;
              <br />
              Clara Jaszczyszyn
              <br />
              Pauline Hauswirth
              <br />
              Eleonore Grimbert
              <br />
              Stephanie Padel
              <br />
              <br />
            </p>
            <p>
              <strong>Cellos </strong>&nbsp;
              <br /> <br />
              Maia Collette
              <br />
              Caro Sypniewsky
              <br />
              Jeremie Billet
              <br />
              Ella Jarridge
              <br />
              Maude Pages
              <br />
              Alexis Derouin
              <br />
              Solene Chevalier
              <br />
              Clara Germont
              <br />
              Justine Metral
              <br />
              Rafael Cumont-Vioque
              <br />
              <br />
            </p>
            <p>
              <strong>Altos </strong>
              <br /> <br />
              Alexandra Kondo
              <br />
              Camille Borsarello
              <br />
              Elsa Seger
              <br />
              Antonin Lefaure
              <br />
              Caroline Pauvert
              <br />
              Maeva Laignelot
              <br />
              Ivan Cerveau
              <br />
              Oriane Pocard
              <br />
              Julien Lo Pinto
              <br />
              Lise Orivel
            </p>
          </div>
          <br />
          <hr />
          <br />
          <br />
          <div>
            <p>
              Conductor: Thomas Roussel <br />
              Orchestra contractor: Lison Favard
              <br />
              Sound engineer & score mixer: Stéphane Reichart
              <br />
              Orchestra recorded at La Seine Musicale, Boulogne-Billancourt -
              France
              <br />
            </p>
          </div>
          <div className="bloc-3">
            <p>
              Contact :{" "}
              <a href="mailto:contact@w-av-e.com">contact@w-av-e.com</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
