import React, { useState, useEffect, useRef } from "react";
import "./HeaderHome.css";
import {
  TwitchEmbed,
  TwitchEmbedLayout,
  TwitchEmbedTheme,
} from "twitch-player";

let embed = null;

function HeaderHome(props) {
  return (
    <div className="header-home" id="header">
      {props.live ? (
        <Live locale={props.locale} data={props} />
      ) : (
        <Counter data={props} />
      )}
    </div>
  );
}
function Counter(props) {
  return (
    <>
      <div className="container-header-vid"></div>
      <div
        className={
          props.data.about
            ? "counter-container open-about"
            : "counter-container"
        }
      >
        {false && (
          <>
            <div className="counter-item days">
              <span className="counter-days">{props.data.date.days}</span>
              <span>Days</span>
            </div>
            <div className="separator">:</div>
            <div className="counter-item hours">
              <span className="counter-hours">{props.data.date.hours}</span>
              <span>hours</span>
            </div>
            <div className="separator">:</div>
            <div className="counter-item minutes">
              <span className="counter-minutes">{props.data.date.minutes}</span>
              <span>minutes</span>
            </div>
          </>
        )}
      </div>

      <div
        className={props.data.about ? "before-live open-about" : "before-live"}
      >
        THANK YOU!
      </div>
    </>
  );
}

function Live(props) {
  const [hasStream, setHasStream] = useState(false);
  const [showIntro, setShowIntro] = useState(true);

  useEffect(() => {
    if (!hasStream) {
      embed = new TwitchEmbed("twitch-embed", {
        width: 1280,
        height: 720,
        channel: "screenclub",
        layout: TwitchEmbedLayout.VIDEO,
        theme: TwitchEmbedTheme.DARK,
        muted: true,
        autoplay: true,
        controls: false,
      });
      setHasStream(true);
    }

    //setHasStream(true);
    const btn = document.getElementById("header");
    if (btn) {
      btn.addEventListener("click", (event) => {
        embed.setMuted(false);
        embed.setVolume(1.0);
        embed.play();
        setShowIntro(false);
      });
    }
  }, [showIntro, props.locale]);

  console.log(showIntro);

  function en() {
    return (
      <div className="intro">
        <div className="intro-txt">
          If the ocean were both a conductor and a director, what artwork would
          it create ?
          <br />
          <br />
          W/AV/E explores and exhibits its creative faculty in an intriguing,
          hybrid experience. When you’re ready, make sure that sound is turned
          on and tap anywhere on the screen to start the experience !
          <br />
          <br />
          <div className={"credits-intro"}>
            <p>
              Created by{" "}
              <a
                target="_blank"
                href="https://thomasroussel.fr/"
                rel="noreferrer"
              >
                Thomas Roussel
              </a>
              , powered by Melco Resorts &amp; Entertainment for
              <a
                target="_blank"
                href="https://www.cityofdreamsmacau.com/en/whats-on/art-macao"
                rel="noreferrer"
              >
                {" "}
                &ldquo;海洋LA MER&rdquo; Art Exhibition
              </a>
              , the Special Exhibition of &ldquo;Art Macao: Macao International
              Art Biennale 2021&rdquo;. You are welcome to join us in our
              support for Ocean conservation efforts through{" "}
              <a
                target="_blank"
                href="https://fundraise.parley.tv/give/348499/#!/donation/checkout"
                rel="noreferrer"
              >
                Parley for the Oceans
              </a>
              .
            </p>
            <br />
            <br />
            <button className="start-button" id="start-btn">
              START THE EXPERIENCE
            </button>
          </div>
        </div>
      </div>
    );
  }

  function cn() {
    return (
      <div className="intro">
        <div className="intro-txt">
          如果海洋是一位導演或指揮家，它會創造怎樣的藝術？W/AV/E
          以有趣的混合體驗，探索及展示其創意特色。
          請確保聲音已調至適當的音量，然後點擊屏幕觀賞。
          <br />
          <br />
          <div className={"credits-intro"}>
            <p>
              此作品由{" "}
              <a
                target="_blank"
                href="https://thomasroussel.fr/"
                rel="noreferrer"
              >
                Thomas Roussel
              </a>
              為新濠博亞娛樂的“藝文薈澳：澳門國際藝術雙年展2021”特展《{" "}
              <a
                target="_blank"
                href="https://www.cityofdreamsmacau.com/en/whats-on/art-macao"
                rel="noreferrer"
              >
                海洋LA MER》 藝術展
              </a>
              所創作。歡迎參加者捐款以支持
              <a
                target="_blank"
                href="https://fundraise.parley.tv/give/348499/#!/donation/checkout"
                rel="noreferrer"
              >
                Parley for the Oceans.
              </a>
            </p>
            <br />
            <br />
            <button className="start-button" id="start-btn">
              START THE EXPERIENCE
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {showIntro && <>{props.locale === "EN" ? en() : cn()}</>}
      <div
        className="texture-bg-live bg-grain"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "/grain-texture-compress.png"
          })`,
        }}
      ></div>
      <div className="texture-bg-live bg-capture"></div>
      <div className="container-header-live">
        <div className="live-btn">Live</div>
        <div className={"video-background"} id="twitch-embed"></div>
      </div>
    </>
  );
}

export default HeaderHome;
