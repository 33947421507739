export default function About(props) {
  return props.locale === "EN" ? en(props) : cn(props);
}

function cn(props) {
  return (
    <div className="content-about-container">
      <div className="container-about">
        <svg
          onClick={props.closeAbout}
          viewport="0 0 20 20"
          width="20"
          height="20"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="0" y="0" width="100%" height="100%" fillOpacity="0" />
          <line x1="1" y1="21" x2="21" y2="1" stroke="black" strokeWidth="3" />
          <line x1="1" y1="1" x2="21" y2="21" stroke="black" strokeWidth="3" />
        </svg>
      </div>
      <div className="content-about">
        <h1 className=""> W/AV/E</h1>
        <h2 className="">海洋為藝術家</h2>
        <p className="subtitle-about">
          <b>Thomas Roussel的實驗性藝術作品</b>
        </p>
        <br />
        <div></div>
        <p>
          如果海洋是導演或指揮，它會創造甚麼樣的藝術？W/AV/E以有趣的混合體驗，探索及展示其創意特色。
        </p>
        <br />
        <div className="bloc-1">
          <p>
            這件藝術品以
            <a
              target="_blank"
              href="http://www.thomasroussel.fr/"
              rel="noreferrer"
            >
              Thomas Roussel
            </a>
            的音樂作品為基礎：由 50
            位音樂家組成的樂隊以各種弦樂器（包括小提琴、大提琴、中提琴和低音提琴）演奏波浪般的弦樂。作為一位作曲家及指揮家，{" "}
            <a
              target="_blank"
              href="http://www.thomasroussel.fr/"
              rel="noreferrer"
            >
              Thomas Roussel
            </a>
            希望能夠以弦樂器的多樣化及自然感覺，演繹與大海息息相關的各種聲音、情感和紋理。
          </p>

          <p>
            海洋便接着主導這件作品。放置於加拿大和美國太平洋交界的水下麥克風實時記錄海底的各種聲音。這些海洋數據透過藝術家
            <a target="_blank" href="https://robinmeier.net/" rel="noreferrer">
              Robin Meier
            </a>{" "}
            精心設計的演算法，激活、融合及演變{" "}
            <a
              target="_blank"
              href="http://www.thomasroussel.fr/"
              rel="noreferrer"
            >
              Thomas Roussel
            </a>
            的作品。海洋及虎鯨等海洋生物游動的聲音都被紀錄下來，仿似指揮家於演奏時給眾弦樂器發號施令。
            <br />
            <br />
            為了創作多感官體驗，{" "}
            <a
              target="_blank"
              href="https://www.arnaudlaffond.com/"
              rel="noreferrer"
            >
              Arnaud Laffond
            </a>
            為這段具吸引力及獨一無二的配樂度身定製了一段影片，並由{" "}
            <a
              target="_blank"
              href="https://www.screen-club.com/"
              rel="noreferrer"
            >
              Martial Geoffre Rouland
            </a>{" "}
            製作的軟件進行調解。這些電腦軟件以集有 20,000
            種海洋生物游動狀態的數據庫為參考。觀眾可透過此作品沉浸在視覺和音樂的海底景觀中，感覺既熟悉又夢幻，直觀既機械化，緩和亦恐懼。像海洋潮汐一樣，W/AV/E不斷在移動而且旋律不會重複。
          </p>
          <br />
          <p>
            W/AV/E在新濠博亞娛樂與合作環保團體{" "}
            <a
              target="_blank"
              href="https://fundraise.parley.tv/give/348499/#!/donation/checkout"
              rel="noreferrer"
            >
              Parley for the Oceans
            </a>
            支持下，為新濠的“藝文薈澳：澳門國際藝術雙年展2021”特展《海洋LA MER》
            藝術展所製訂而成。此作品由2021年7月16日至10月尾於新濠天地水舞間劇院展出。
            <a
              target="_blank"
              href="https://fundraise.parley.tv/give/348499/#!/donation/checkout"
              rel="noreferrer"
            >
              歡迎所有參觀者和觀眾向Parley for the Oceans捐款。
            </a>
          </p>
          <br />
        </div>
        <br />
        <br />
        <hr />
        <br />
        <br />
        <p className="">
          <div className="bold">
            W/AV/E 是由 Thomas Roussel 執導的藝術作品，並由Robin Meier、Arnaud
            Laffond及Martial Geoffre Rouland 合力支援。
          </div>
        </p>
        <br />

        <div className="bloc-2-cn">
          <p>
            <div className="bold">
              Thomas Roussel <br />
              作曲家及藝術總監
            </div>
            <br />
            <div className="italic">
              Thomas
              Roussel是一知名法籍作曲家、編曲家和指揮家。他曾與世界各地的許多著名音樂家、錄音室和管弦樂團合作，如倫敦交響樂團、阿比路錄音室、法國廣播愛樂樂團等。近年他一直在試驗以不同手法與管弦樂團合作，為各品牌的時裝秀（包括Chanel、Dior
              Couture、Kenzo、Givenchy
              Couture、Nike等）和活動（包括由札哈‧哈蒂設計的澳門新濠天地摩珀斯酒店的開幕典禮、紐約市林肯中心的
              Apple TV+
              世界首演、卡地亞於首爾、巴黎、蒙特卡洛舉辦的高級珠寶展等等）創作世界級的表演場景和樂章。Thomas
              Roussel還於 2017 年以別名 Prequell
              發行首張個人專輯，目前正在準備第二張專輯。
            </div>
          </p>
          <br />
          <p>
            <div className="bold">
              Robin Meier <br />
              聲音人工智能及機器學習
            </div>
            <br />
            <div className="italic">
              藝術家 Robin Meier
              探索自然智能和人工智能的出現以及人類在機器世界中的定位。他的作品曾於多個國際展覽展出，如東京宮、巴黎當代藝術博覽會、巴黎現代藝術博物館、瑞士巴塞爾藝術展、第
              11 屆上海雙年展及紐約阿仙奴當代藝術展。Robin
              Meier亦是法國現代音樂研究所及龐畢度國家藝術和文化中心的長期合作伙伴。
            </div>
          </p>
          <br />

          <p>
            <div className="bold">
              {" "}
              Arnaud Laffond <br />
              視覺數碼藝術家
            </div>
            <br />

            <div className="italic">
              Arnaud
              Laffond是位影片藝術家並於里昂開設數碼工作室。他的作品特點是利用了電腦創作的虛擬環境和材料。他的作品處於科幻小說的邊界，介於抽象與現實、烏托邦與反烏托邦、建築與風景之間。他創作各方面的影片動畫，例如藝術裝置、gif
              和印象派作品。
            </div>
          </p>
          <br />
          <p>
            <div className="bold">
              Martial Geoffre Rouland
              <br />
              視覺人工智能及機器學習
            </div>
            <br />
            <div className="italic">
              畢業於巴塞羅那高等設計與工程學院的Martial Geoffre
              Rouland專門創作數碼藝術及互動性裝置。他的作品與藝術家、獨立工作室和國際公司於機器學習、UI/UX
              網絡體驗、舞台燈光、數據形象化、人機交互、電腦視覺、擴增虛擬實境和圖形設計等各方面合作而成。
            </div>
          </p>
        </div>
        <br />
        <div className="bloc-3">
          <p>
            聯絡: <a href="mailto:contact@w-av-e.com">contact@w-av-e.com</a>
          </p>
        </div>
      </div>
    </div>
  );
}
function en(props) {
  return (
    <div className="content-about-container">
      <div className="container-about">
        <svg
          onClick={props.closeAbout}
          viewport="0 0 20 20"
          width="20"
          height="20"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="0" y="0" width="100%" height="100%" fillOpacity="0" />
          <line x1="1" y1="21" x2="21" y2="1" stroke="black" strokeWidth="3" />
          <line x1="1" y1="1" x2="21" y2="21" stroke="black" strokeWidth="3" />
        </svg>
      </div>
      <div className="content-about">
        <h1 className=""> W/AV/E</h1>
        <h2 className="">The Ocean as the Artist</h2>
        <p className="subtitle-about">
          <b>An experimental artwork directed by Thomas Roussel</b>
        </p>
        <br />
        <div></div>
        <p>
          If the ocean were both a conductor and a director, what artwork would
          it create ?
          <br /> W/AV/E explores and exhibits its creative faculty in an
          intriguing, hybrid experience.
        </p>
        <br />
        <div className="bloc-1">
          <p>
            At the basis of this artwork is{" "}
            <a
              target="_blank"
              href="http://www.thomasroussel.fr/"
              rel="noreferrer"
            >
              Thomas Roussel&rsquo;s{" "}
            </a>
            musical composition: waves of sound played by a 50-musician
            orchestra made up exclusively of string instruments (violins,
            cellos, violas and double basses). A composer and conductor himself,
            Thomas Roussel wanted to translate the vast range of sounds,
            emotions and textures associated with the sea through the versatile
            practice and organic feeling of string instruments.
          </p>

          <p>
            The ocean, then, takes over the work. Thanks to underwater
            microphones placed in the Pacific Ocean, at the border between
            Canada and the United States, the sound of the undersea is recorded
            in real time.
            <br />
            <br />
            This oceanographic data activates, plays and transforms Thomas
            Roussel&rsquo;s composition through an algorithm elaborated with the
            artist{" "}
            <a target="_blank" href="https://robinmeier.net/" rel="noreferrer">
              Robin Meier
            </a>
            . The sounds of the water and its inhabitants &ndash; orcas pass by
            the microphones at several occasions &ndash; give cue to the string
            instruments just as a conductor would.
          </p>

          <p>
            In order to create a multisensory experience, this fascinating,
            inimitable soundtrack generates a video, designed by{" "}
            <a
              target="_blank"
              href="https://www.arnaudlaffond.com/"
              rel="noreferrer"
            >
              Arnaud Laffond
            </a>
            , and through the mediation of computer software made by{" "}
            <a
              target="_blank"
              href="https://www.screen-club.com/"
              rel="noreferrer"
            >
              Martial Geoffre Rouland
            </a>
            . These softwares tap into a database collecting the movements of
            20,000 marine species. The viewer is immersed in this visual and
            musical submarine landscape that feels alternatively familiar and
            dreamy, intuitive and robotic, soothing and frightening. Just like
            the ocean tide, W/AV/E moves perpetually and is never twice the
            same&#8230;
          </p>
          <br />
          <p>
            W/AV/E was created on the occasion of{" "}
            <a
              target="_blank"
              href="https://www.cityofdreamsmacau.com/en/whats-on/art-macao"
              rel="noreferrer"
            >
              Art Macau
            </a>
            , in partnership with&nbsp;
            <a
              target="_blank"
              href="https://fundraise.parley.tv/give/348499/#!/donation/checkout"
              rel="noreferrer"
            >
              Parley for the Oceans
            </a>{" "}
            and thanks to Melco&rsquo;s sponsorship. It will be exhibited at the
            House of Dancing Water in City of Dreams, from July 16 to end of
            October 2021. <br />
            <br />
            All visitors and viewers are welcome&nbsp;
            <a
              target="_blank"
              href="https://fundraise.parley.tv/give/348499/#!/donation/checkout"
              rel="noreferrer"
            >
              to make donations to Parley for the Ocean
            </a>
            .
          </p>
        </div>
        <br />
        <br />
        <hr />
        <br />
        <br />
        <p className="">
          <div className="bold">
            W/AV/E is an artwork directed by Thomas Roussel, with contributions
            from Robin Meier, Arnaud Laffond, Martial Geoffre Rouland.
          </div>
        </p>
        <br />

        <div className="bloc-2">
          <p>
            <div className="bold">
              Thomas Roussel <br />
              Music composer &amp; artistic director
            </div>
            <br />
            <div className="italic">
              Thomas Roussel is a French composer, orchestrator and conductor.
              He has collaborated with many prestigious musicians, studios and
              orchestras around the world, such as the London Symphony
              Orchestra, Abbey Road Studios, Orchestre Philharmonique de Radio
              France&#8230; He experiments new ways of using the orchestra by
              creating state-of-the-art scenography and music composition for
              fashion shows (Chanel, Dior Couture, Kenzo, Givenchy Couture,
              Nike&#8230;) and events (inauguration of Zaha Hadid's Morpheus
              Tower in Macau, launch of Apple TV+ in New York at the Lincoln
              Center in New York, Cartier fine jewelry shows in Seoul, Paris,
              Monte Carlo&#8230;). He also released a solo album under the alias
              Prequell in 2017 and is currently working on the second one.
            </div>
          </p>
          <br />
          <p>
            <div className="bold">
              Robin Meier <br />
              Audio artificial intelligence &amp; machine learning&#8232;
            </div>
            <br />
            <div className="italic">
              Artist Robin Meier&#160;explores the emergence of natural and
              artificial intelligence and the role of humans in a world of
              machines. His works have been&#160;shown internationally,
              in&#160;institutions&#160;such as&#160;Palais de Tokyo, FIAC and
              Museum of Modern Art in Paris, Art Basel, Switzerland, the 11th
              Shanghai Biennale, Arsenal Contemporary New York. Robin
              Meier&#160;is a longtime collaborator at&#160;IRCAM / Centre
              Pompidou.
            </div>
          </p>
          <br />
          <p>
            <div className="bold">
              {" "}
              Arnaud Laffond <br />
              Visual digital artist&#8232;
            </div>
            <br />

            <div className="italic">
              Arnaud Laffond is a video artist, plastic digitalis workbench
              based in Lyon. His work is characterized by the creation of
              virtual environments and materials generated by computer. His
              works are situated on the verge of science fiction, halfway
              between abstraction and the representation, utopia and dystopia,
              architecture and landscape. He works with diverse aspects of
              video, such as installation, animated gif and impression.
            </div>
          </p>
          <br />
          <p>
            <div className="bold">
              Martial Geoffre Rouland
              <br />
              Visual artificial intelligence &amp; machine learning{" "}
            </div>
            <br />
            <div className="italic">
              Graduated from the Superior School of Design &amp; Engineering of
              Barcelona, Martial Geoffre Rouland focuses on digital art and
              interactive installations. His projects are the result of various
              collaborations with artists, independent studios and international
              companies, on Machine Learning, UI/UX web experiences, stage
              lighting, data visualization, human/computer interaction, computer
              vision, augmented reality and graphic design.
            </div>
          </p>
        </div>
        <br />
        <div className="bloc-3">
          <p>
            Contact : <a href="mailto:contact@w-av-e.com">contact@w-av-e.com</a>
          </p>
        </div>
      </div>
    </div>
  );
}
