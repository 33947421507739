import React from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import HeaderHome from "./components/HeaderHome";
// import HeaderHome from './components/HeaderHome';
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
function ContentAbout() {
  return (
    <div className="content-about-container">
      <div className="content-about"></div>
    </div>
  );
}

export default class App extends React.Component {
  constructor() {
    super();
    this.state = {
      about: false,
      timeLeft: {},
      live: false,
      locale: "EN",
    };
  }

  clickAbout = (menu, lang) => {
    this.setState({ about: true, menu });
  };

  setLocale = (locale) => {
    this.setState({ locale: locale });
  };

  closeAbout = () => {
    this.setState({ about: false });
  };

  addZero = (result) => {
    if (result < 10) {
      result = "0" + result;
    }
    return result;
  };

  calculateTimeLeft = () => {
    let year = new Date().getFullYear();
    const difference = +new Date(`06/10/${year}`) - +new Date();

    let timeLeft;
    if (difference > 0) {
      timeLeft = {
        days: this.addZero(Math.floor(difference / (1000 * 60 * 60 * 24))),
        hours: this.addZero(Math.floor((difference / (1000 * 60 * 60)) % 24)),
        minutes: this.addZero(Math.floor((difference / 1000 / 60) % 60)),
        //seconds: Math.floor((difference / 1000) % 60)
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        //seconds: 0
      };
      this.setState({
        live: true,
      });
    }
    return timeLeft;
  };

  componentDidMount() {
    this.setState({
      timeLeft: this.calculateTimeLeft(),
    });
  }

  componentDidUpdate() {
    if (!this.state.live) {
      const timer = setTimeout(() => {
        this.setState({
          timeLeft: this.calculateTimeLeft(),
        });
      }, 1000);
      return () => clearTimeout(timer);
    }
  }

  render() {
    return (
      <>
        <div className={this.state.about ? "display-about" : "display-content"}>
          <Navbar
            menu={this.state.menu}
            about={this.state.about}
            clickAbout={this.clickAbout}
            closeAbout={this.closeAbout}
            setLocale={this.setLocale}
            locale={this.state.locale}
          />
          <HeaderHome
            locale={this.state.locale}
            date={this.state.timeLeft}
            live={this.state.live}
          />
        </div>
        <div className="footer">
          <a
            href="https://www.thomasroussel.fr/"
            target="_blank"
            rel="noreferrer"
            className="footer-link"
          >
            WWW
          </a>

          <a
            href="http://instagram.com/thomasroussel"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon></InstagramIcon>
          </a>
          <a
            href="https://www.facebook.com/beforeprequell"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon></FacebookIcon>
          </a>
          <a
            href="https://www.youtube.com/channel/UCaELtJTOtwkzgdU6rljaAsw"
            target="_blank"
            rel="noreferrer"
          >
            <YouTubeIcon></YouTubeIcon>
          </a>
        </div>
      </>
    );
  }
}
