import React, { useState, useEffect } from "react";
//import { Link } from 'react-router-dom';
import "./Navbar.css";
import Credits from "./Credits";
import ContentScenes from "./ContentScenes";
import About from "./About";
function Navbar(props) {
  let content = null;

  let menu = {
    about: "about",
    behind: "behind the scenes",
    credits: "credits",
    lang: "中国人",
  };
  if (props.locale === "CN") {
    menu = {
      about: "关于",
      behind: "在幕后",
      credits: "学分",
      lang: "EN",
    };
  }

  if (props.about) {
    switch (props.menu) {
      case "about":
        content = <About locale={props.locale} closeAbout={props.closeAbout} />;
        break;

      case "behind":
        content = (
          <ContentScenes locale={props.locale} closeAbout={props.closeAbout} />
        );
        break;

      case "credits":
        content = (
          <Credits locale={props.locale} closeAbout={props.closeAbout} />
        );
        break;

      default:
        break;
    }
  }

  return (
    <div className={"container-navbar"}>
      <div className="container-logo" onClick={props.closeAbout}>
        <img src={process.env.PUBLIC_URL + "/wave_logo.png"} />
      </div>
      <div className="navbar-right">
        <div
          className="container-about"
          onClick={() => props.clickAbout("about")}
        >
          <h2>{menu.about}</h2>
        </div>
        <div
          className="container-about"
          onClick={() => props.clickAbout("behind")}
        >
          <h2>{menu.behind}</h2>
        </div>

        <div
          className="container-about"
          onClick={() => props.clickAbout("credits")}
        >
          <h2>{menu.credits}</h2>
        </div>

        <div
          className="container-about"
          onClick={() => {
            props.setLocale(props.locale === "EN" ? "CN" : "EN");
          }}
        >
          <h2>{menu.lang}</h2>
        </div>
      </div>

      {props.about ? content : null}
    </div>
  );
}

export default Navbar;
