import Gallery from "./Gallery";
export default function ContentScenes(props) {
  return props.locale === "EN" ? en(props) : cn(props);
}

function cn(props) {
  return (
    <div className="content-about-container">
      <div className="container-about">Chine</div>
    </div>
  );
}

function en(props) {
  let elements = [];

  for (let i = 1; i < 15; i++) {
    elements.push({ src: i + ".jpg" });
  }

  return (
    <div className="content-about-container">
      <div className="container-about">
        <svg
          onClick={props.closeAbout}
          viewport="0 0 20 20"
          width="20"
          height="20"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="0" y="0" width="100%" height="100%" fillOpacity="0" />
          <line x1="1" y1="21" x2="21" y2="1" stroke="black" strokeWidth="3" />
          <line x1="1" y1="1" x2="21" y2="21" stroke="black" strokeWidth="3" />
        </svg>
      </div>
      <div className="content-about no-max-width">
        <h1 className=""> W/AV/E</h1>
        <h2 className="">The Ocean as the Artist</h2>
        <p className="subtitle-about">
          <b>An experimental artwork directed by Thomas Roussel</b>
        </p>
        <br />
        <iframe
          width="100%"
          height="600"
          src="https://www.youtube.com/embed/hgZ01Wt6RT4"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <Gallery elements={elements}></Gallery>
      </div>
    </div>
  );
}
